export const pageWrapperClass =
  'flex flex-col items-center w-full min-h-screen gap-4 py-4 mb-4 lg:p-8 lg:mt-6 rounded-[20px] bg-white font-arizona-sans lg:w-5/12 lg:min-h-fit lg:shadow-[0px_16px_36px_-20px_rgba(12,1,47,0.17),0px_16px_64px_0px_rgba(47,5,88,0.02),0px_12px_60px_0px_rgba(0,0,0,0.05)]';
export const formWrapperClass =
  'flex flex-col items-center justify-center w-full gap-4 px-4 pb-4 pt-2';
export const formSubmitSectionClass =
  'flex flex-col items-center justify-end w-full gap-4 lg:py-4 pt-4';
export const formErrorClass =
  'w-full flex justify-between items-center bg-[#F5C7D1] text-[#64172B] border-[1px] border-[#E54666] p-2 rounded-[8px]';
export const formSuccessClass =
  'w-full bg-[#E7F9F5] text-[#067A6F] border-[1px] border-[#067A6F] rounded-[8px] overflow-hidden';
